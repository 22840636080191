import { Box, Button, Stack } from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryBgColor } from "../utils/color";
import { Link, useNavigate } from "react-router-dom";
import {
  POST_STATUS,
  REQUEST_LIVE_STATUS,
  requestStatus,
} from "../constants/constants";
import { UseDcCallNow } from "../hooks/use-dc-call-now";
import { SomethingWentWrongModal } from "../modals/someting-went-wrong";
import { PreRequestFormModal } from "../modals/pre-request-form";
import { Delete, Phone, SensorOccupied } from "@mui/icons-material";
import { setSignInRedirectPath } from "../utils/generals";

export const ViewPropertyFooter = ({
  postData,
  requestRes,
  isOwnerViewPost,
  isOwnerNavigatedFromMyPosts,
  isCallNowModalOpen,
  setIsCallNowModalOpen,
  setIsRequestModalOpen,
  isUserSignedIn,
  userContactNumber,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  const { dcCallNowInfo, setDcCallNowInfo, handleDcCallNow } = UseDcCallNow(
    postData,
    isUserSignedIn,
    userContactNumber
  );

  const handleContactOwner = () => {
    if (!isUserSignedIn || !userContactNumber) {
      setSignInRedirectPath();
      navigate("/login");
      return;
    }
    setIsCallNowModalOpen(true);
  };

  if (postData?.paymentStatus !== "success") {
    return (
      <>
        <Spacer space={50} />
      </>
    );
  }

  if (postData?.postType === "direct-calling") {
    return (
      <>
        <PreRequestFormModal
          isCallNowModalOpen={isCallNowModalOpen}
          setIsCallNowModalOpen={setIsCallNowModalOpen}
          setIsRequestModalOpen={setIsRequestModalOpen}
          postChargePercentage={postData?.postChargePercentage}
          handleDcCallNow={handleDcCallNow}
          postType={postData?.postType}
          dcCallNowInfo={dcCallNowInfo}
          isRequestApproved={
            requestRes?.requestData?.requestStatus === requestStatus.approved
          }
          ownerContactNumber={requestRes?.requestData?.ownerContactNumber}
        />
        <SomethingWentWrongModal
          value="isError"
          state={dcCallNowInfo}
          setState={setDcCallNowInfo}
        />
        <Spacer space={75} />
        <Stack alignItems="center">
          <Button
            variant="contained"
            sx={{
              position: "fixed",
              bottom: "25px",
              width: "90%",
              boxShadow: "8",
            }}
            startIcon={<Phone />}
            onClick={handleContactOwner}
          >
            Call Owner
          </Button>
        </Stack>
      </>
    );
  }

  if (isOwnerViewPost) {
    if (
      postData?.paymentStatus === "success" &&
      postData?.postStatus === POST_STATUS.ACTIVE
    ) {
      return (
        <Box>
          <Spacer space={75} />
          <Stack alignItems="center">
            <Button
              component={Link}
              to={"/post-requests"}
              state={{
                navigatedData: {
                  postUid: postData?.postUid,
                },
              }}
              sx={{
                position: "fixed",
                bottom: "25px",
                width: "90%",
                boxShadow: "8",
              }}
              variant="contained"
            >
              View Requests
            </Button>
          </Stack>
        </Box>
      );
    } else {
      return (
        <>
          <Spacer space={25} />
        </>
      );
    }
  }

  if (requestRes?.isLoading) {
    return <></>;
  }

  if (
    postData?.postStatus === POST_STATUS.EXPIRED ||
    postData?.postStatus === POST_STATUS.REMOVED
  ) {
    return (
      <>
        {requestRes?.requestData?.liveStatus === REQUEST_LIVE_STATUS.ACTIVE ? (
          <Box>
            <Spacer space={75} />
            <Stack alignItems="center">
              <Button
                variant="contained"
                sx={{
                  position: "fixed",
                  bottom: "25px",
                  width: "90%",
                  boxShadow: "8",
                }}
                startIcon={<Delete />}
                onClick={() =>
                  handleDeleteRequest(requestRes?.requestData?.requestUid)
                }
              >
                Delete Request
              </Button>
            </Stack>
          </Box>
        ) : (
          <>
            <Spacer space={50} />
          </>
        )}
      </>
    );
  }

  if (
    postData?.postStatus === POST_STATUS.IN_REVIEW ||
    postData?.postStatus === POST_STATUS.ACTION_REQUIRED
  ) {
    return (
      <>
        <Spacer space={50} />
      </>
    );
  }

  return (
    <>
      <PreRequestFormModal
        isCallNowModalOpen={isCallNowModalOpen}
        setIsCallNowModalOpen={setIsCallNowModalOpen}
        setIsRequestModalOpen={setIsRequestModalOpen}
        postChargePercentage={postData?.postChargePercentage}
        handleDcCallNow={handleDcCallNow}
        postType={postData?.postType}
        dcCallNowInfo={dcCallNowInfo}
        isRequestApproved={
          requestRes?.requestData?.requestStatus === requestStatus.approved
        }
        ownerContactNumber={requestRes?.requestData?.ownerContactNumber}
      />
      <Box>
        {requestRes?.requestData ? (
          <>
            {requestRes?.requestData?.requestStatus ===
              requestStatus.approved && (
              <Box>
                <Spacer space={75} />
                <Stack alignItems="center">
                  <Button
                    variant="contained"
                    sx={{
                      position: "fixed",
                      bottom: "25px",
                      width: "90%",
                      boxShadow: "8",
                    }}
                    startIcon={<Phone />}
                    onClick={handleContactOwner}
                  >
                    Call Owner
                  </Button>
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <Box>
            <Spacer space={75} />
            <Stack alignItems="center">
              <Button
                variant="contained"
                sx={{
                  position: "fixed",
                  bottom: "25px",
                  width: "90%",
                  boxShadow: "8",
                }}
                startIcon={<SensorOccupied />}
                onClick={handleContactOwner}
              >
                Contact Owner
              </Button>
            </Stack>
          </Box>
        )}
        {requestRes?.requestData?.requestStatus && <Spacer space={50} />}
      </Box>
    </>
  );
};
