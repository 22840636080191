import { Box, Button, Stack, Typography } from "@mui/material";
import { MainPropertyCard } from "./MainPropertyCard";
import { Center } from "./Center";
import { Spacer } from "./Spacer";
import NoSearchFilterResultIllustration from "../illustrations/no-search-filter-result.svg";
import { primaryColor } from "../utils/color";

export const FilterResults = ({
  filterResults,
  mainFilterInfoProvider,
  setMainFilterInfoProvider,
  setDisableHandlers,
}) => {
  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ paddingX: "18px" }}
        >
          <Stack spacing={-0.4}>
            <Stack direction="row" spacing={0.6} alignItems="flex-end">
              <Typography
                sx={{
                  fontSize: "19px",
                  fontWeight: "600",
                }}
              >
                {filterResults?.length === 0 ? "No" : filterResults?.length}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                results found near
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                color: primaryColor[800],
              }}
            >
              "{mainFilterInfoProvider?.locality}"
            </Typography>
          </Stack>
          <Button onClick={() => setMainFilterInfoProvider(null)}>clear</Button>
        </Stack>
        <Spacer space={10} />
        {filterResults.length === 0 ? (
          <>
            <Spacer space={50} />
            <Center>
              <Box>
                <img
                  width="260px"
                  height="260px"
                  alt=""
                  src={NoSearchFilterResultIllustration}
                />
              </Box>
            </Center>
          </>
        ) : (
          <Box sx={{ marginX: "12px" }}>
            <Stack spacing={3}>
              {filterResults.map((postData, i) => (
                <MainPropertyCard
                  key={i}
                  postData={postData}
                  isOwnerNavigatedFromMyPosts={false}
                  setDisableHandlers={setDisableHandlers}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};
