import { Box, Stack } from "@mui/material";
import { FullScreen } from "./FullScreen";
import { useEffect } from "react";
import { resetThemeColor, updateThemeColor } from "../utils/color";
import "./logo-loader.css";

export const LogoLoader = ({ updateTheme }) => {
  // useEffect(() => {
  //   if (updateTheme) {
  //     updateThemeColor();

  //     return () => {
  //       resetThemeColor();
  //     };
  //   }
  // }, [updateTheme]);

  return (
    <>
      <Box>
        <FullScreen>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <div class="loader2"></div>
            <div class="loader"></div>
          </Stack>
        </FullScreen>
      </Box>
    </>
  );
};
