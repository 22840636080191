import { Box, Skeleton, Stack } from "@mui/material";
import { SkeletonCard } from "./dashboard-loader";
import { Spacer } from "./Spacer";

export const FilterLoader = () => {
  return (
    <>
      <Box sx={{ paddingX: "10px" }}>
        <Stack spacing={-0.8}>
          <Stack direction="row" justifyContent="space-between">
            <Skeleton
              animation="wave"
              variant="text"
              sx={{ fontSize: "1.7rem", width: "60%" }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              sx={{ fontSize: "1.7rem", width: "20%" }}
            />
          </Stack>
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "2rem", width: "45%" }}
          />
        </Stack>
        <Spacer space={8} />
        <Stack spacing={2.5}>
          {Array.from({ length: 3 }).map((_, i) => (
            <SkeletonCard key={i} />
          ))}
        </Stack>
      </Box>
    </>
  );
};
