import { Box, Divider } from "@mui/material";
import { Spacer } from "../components/Spacer";
import { MainHeader } from "../components/MainHeader";
import { FilterActionSheet } from "../components/FilterActionSheet";
import { PropertyCardSection } from "../components/PropertyCardSection";
import { MainFooter } from "../components/MainFooter";
import { primaryBgColor, primaryColor } from "../utils/color";
import { ProfileContext } from "../context/ProfileContextProvider";
import { useContext, useState } from "react";
import { FilterResults } from "../components/FilterResults";
import { HomePostsContext } from "../context/HomePostsContextProvider";
import { FilterResultsContext } from "../context/FilterResultsContextProvider";
import { ErrorParachuteComponent } from "../components/ErrorParachuteComponent";
import { AppInstallBanner } from "../components/app-install-banner";
import { MainBanner } from "../components/main-banner";
import { DashboardLoader } from "../components/dashboard-loader";
import { FilterLoader } from "../components/filter-loader";
import { useSwipeable } from "react-swipeable";

export const Home = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [disableHandlers, setDisableHandlers] = useState(false);
  const { user } = useContext(ProfileContext);
  const { homePostsInfo, isHomePostsLoading, isHomePostsError } =
    useContext(HomePostsContext);
  const {
    filterResultsRes,
    mainFilterInfoProvider,
    setMainFilterInfoProvider,
  } = useContext(FilterResultsContext);

  const isDashboardError = isHomePostsError || filterResultsRes?.isError;

  const swipeHandlers = useSwipeable({
    delta: 50,
    onSwipedRight: () => !disableHandlers && setIsDrawerOpen(!isDrawerOpen),
  });

  return (
    <>
      <Box sx={{ bgcolor: `${primaryBgColor}` }} {...swipeHandlers}>
        <AppInstallBanner />
        <MainHeader
          user={user}
          setMainFilterInfoProvider={setMainFilterInfoProvider}
          disableHandlers={disableHandlers}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
        <Box
          sx={
            {
              // background: `linear-gradient(0deg,  #fff, ${primaryColor[800]}, #fff)`,
            }
          }
        >
          <Spacer space={10} />
          <MainBanner />
          <Spacer space={8} />
        </Box>
        <Divider
          variant="middle"
          sx={{ height: "1px", bgcolor: primaryColor[800] }}
        />
        <Spacer space={14} />
        <Box sx={{ marginX: "6px" }}>
          <FilterActionSheet
            setMainFilterInfoProvider={setMainFilterInfoProvider}
            setDisableHandlers={setDisableHandlers}
          />
        </Box>
        <Spacer space={12} />
        {isHomePostsLoading ? (
          <DashboardLoader />
        ) : filterResultsRes?.isLoading ? (
          <FilterLoader />
        ) : isDashboardError ? (
          <Box>
            <ErrorParachuteComponent />
          </Box>
        ) : filterResultsRes?.filterResultsData ? (
          <FilterResults
            filterResults={filterResultsRes?.filterResultsData}
            mainFilterInfoProvider={mainFilterInfoProvider}
            setMainFilterInfoProvider={setMainFilterInfoProvider}
            setDisableHandlers={setDisableHandlers}
          />
        ) : (
          <PropertyCardSection
            homePostsInfo={homePostsInfo}
            setDisableHandlers={setDisableHandlers}
          />
        )}
        <Spacer space={30} />
        {!(filterResultsRes?.filterResultsData?.length === 0) && <MainFooter />}
      </Box>
    </>
  );
};
