import {
  ArrowBack,
  Edit,
  Favorite,
  FavoriteBorder,
  MoreVert,
  Payment,
  RemoveCircle,
  Share,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { primaryColor } from "../utils/color";
import { useState } from "react";
import { SomethingWentWrongModal } from "./SomethingWentWrongModal";
import { shareProperty } from "../helper/helper";
import { WarningActionModal } from "../modals/warning-action";
import { BackdropLoader } from "./backdrop-loader";
import { UsePostDisable } from "../hooks/use-post-disable";
import { POST_STATUS, SAVED_LIVE_STATUS } from "../constants/constants";

export const ViewPropertyHeader = ({
  isOwnerViewPost,
  postData,
  savedInfoRes,
  handleAddFavorite,
  handleRemvoeFavorite,
}) => {
  const navigate = useNavigate();

  const [isDisablePostModalOpen, setIsDisablePostModalOpen] = useState(false);
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const { postDisableStatus, setpostDisableStatus, handleDisablePost } =
    UsePostDisable(postData, setIsDisablePostModalOpen);
  const isFreePost = postData?.postChargePercentage === "0";

  return (
    <>
      <BackdropLoader isLoading={postDisableStatus?.isLoading} />
      <WarningActionModal
        isOpen={isDisablePostModalOpen}
        setIsOpen={setIsDisablePostModalOpen}
        wrnText="Are you sure you want to remove this post ?"
        actionText="Remove"
        actionFunction={handleDisablePost}
      />
      <SomethingWentWrongModal
        state={postDisableStatus}
        setState={setpostDisableStatus}
      />
      <Menu
        anchorEl={anchorEl}
        open={isMoreMenuOpen}
        onClose={() => setMoreMenuOpen(false)}
        onClick={() => setMoreMenuOpen(false)}
        PaperProps={PaperPropsStyle}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {postData?.paymentStatus === "success" &&
          (postData?.postStatus === POST_STATUS.ACTIVE ||
            postData?.postStatus === POST_STATUS.ACTION_REQUIRED) && (
            <MenuItem onClick={() => setMoreMenuOpen(false)}>
              <Box
                sx={{
                  width: "100%",
                  textDecoration: "none",
                  color: "black",
                }}
                component={Link}
                to="/list-property"
                state={{
                  navigatedData: {
                    editPostUid: postData?.postUid,
                  },
                  navigatedFlag: {
                    isEditPost: true,
                  },
                }}
              >
                <Stack direction="row" alignItems="center">
                  <ListItemIcon>
                    <Edit fontSize="small" sx={{ color: primaryColor[800] }} />
                  </ListItemIcon>
                  <Typography>Edit</Typography>
                </Stack>
              </Box>
            </MenuItem>
          )}
        {!isFreePost && (
          <MenuItem onClick={() => setMoreMenuOpen(false)}>
            <Box
              sx={{
                width: "100%",
                textDecoration: "none",
                color: "black",
              }}
              component={Link}
              to="/receipt"
              state={{
                navigatedData: {
                  postUid: postData?.postUid,
                },
              }}
            >
              <Stack direction="row" alignItems="center">
                <ListItemIcon>
                  <Payment fontSize="small" sx={{ color: primaryColor[800] }} />
                </ListItemIcon>
                <Typography>Payment Details</Typography>
              </Stack>
            </Box>
          </MenuItem>
        )}
        {postData?.postStatus === POST_STATUS.ACTIVE && (
          <MenuItem onClick={() => setMoreMenuOpen(false)}>
            <Box
              sx={{
                width: "100%",
                textDecoration: "none",
                color: "black",
              }}
              onClick={() => setIsDisablePostModalOpen(true)}
            >
              <Stack direction="row" alignItems="center">
                <ListItemIcon>
                  <RemoveCircle
                    fontSize="small"
                    sx={{ color: primaryColor[800] }}
                  />
                </ListItemIcon>
                <Typography>Remove</Typography>
              </Stack>
            </Box>
          </MenuItem>
        )}
      </Menu>

      <Box
        // bgcolor={primaryColor[800]}
        bgcolor="white"
        sx={{ p: "5px", position: "sticky", top: "0", zIndex: "99" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            color="inherit"
            onClick={() =>
              window?.history?.state?.idx > 0 ? navigate(-1) : navigate("/")
            }
          >
            <ArrowBack sx={{ color: primaryColor[800] }} />
          </IconButton>

          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="black"
              fontFamily="Ultra, serif"
            >
              View
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color={primaryColor[800]}
              fontFamily="Ultra, serif"
            >
              {isOwnerViewPost ? "Post" : "Property"}
            </Typography>
          </Stack>

          <Stack direction="row">
            <IconButton
              color="inherit"
              onClick={() => shareProperty(postData?.postUid)}
            >
              <Share sx={{ color: primaryColor[800] }} />
            </IconButton>
            {isOwnerViewPost ? (
              <>
                <IconButton
                  color="inherit"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setMoreMenuOpen(true);
                  }}
                >
                  <MoreVert sx={{ color: primaryColor[800] }} />
                </IconButton>
              </>
            ) : (
              <>
                {!savedInfoRes?.isLoading ? (
                  <Box>
                    {savedInfoRes?.liveStatus === SAVED_LIVE_STATUS.SAVED ? (
                      <IconButton
                        color="inherit"
                        onClick={handleRemvoeFavorite}
                      >
                        <Favorite sx={{ color: primaryColor[800] }} />
                      </IconButton>
                    ) : (
                      <IconButton color="inherit" onClick={handleAddFavorite}>
                        <FavoriteBorder sx={{ color: primaryColor[800] }} />
                      </IconButton>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <IconButton color="inherit">
                      <CircularProgress
                        size="20px"
                        sx={{ color: primaryColor[800], marginTop: "4px" }}
                      />
                    </IconButton>
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

const PaperPropsStyle = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 5,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
