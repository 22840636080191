import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";
import { furnishments } from "../constants/constants";
import { TagsInput } from "react-tag-input-component";
import { FieldHeading } from "../pages/list-property";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import DatePicker from "react-datepicker";
import {
  CustomChip,
  CustomInput,
  PostTypeChip,
  PreferenceChip,
} from "./info-tab-list-property";
import { Info } from "@mui/icons-material";
import { useState } from "react";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { convertFirebaseTimestamp } from "../utils/generals";

export const PropertyInfoTabListProperty = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  isEditPost,
}) => {
  const [postTypeInfoModal, setPostTypeInfoModal] = useState({
    isDirectCallingOpen: false,
    isRequestSubmissionOpen: false,
  });

  return <></>;
};

export const BhkChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.bhk === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "bhk",
          value: `${postPropertyInfo?.bhk === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.bhk === value ? "white" : grey[900]}`,
        backgroundColor: `${postPropertyInfo?.bhk === value ? "" : "white"}`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

export const ParkingChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value1,
  value2,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.parking?.[value1] ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "parking",
          value: {
            [value1]: !postPropertyInfo?.parking?.[value1],
            [value2]: postPropertyInfo?.parking?.[value2],
          },
        })
      }
      sx={{
        color: `${postPropertyInfo?.parking?.[value1] ? "white" : grey[900]}`,
        backgroundColor: `${
          postPropertyInfo?.parking?.[value1] ? "" : "white"
        }`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
  },
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
};
