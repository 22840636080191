import { useContext, useState } from "react";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { firestoreDb, storage } from "../firebase/firebaseConfig";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { POST_STATUS } from "../constants/constants";
import { HomePostsContext } from "../context/HomePostsContextProvider";

export const UseSavePost = (
  postPropertyInfo,
  userUid,
  isEditPost,
  mediaToUpload,
  contactNumber,
  pendingPostInfo,
  setPendingPostInfo
) => {
  const navigate = useNavigate();
  const [postRef, setPostRef] = useState(null);
  const { triggerMyPosts, setTriggerMyPosts } = useContext(MyPostsContext);
  const { triggerHomePosts, setTriggerHomePosts } =
    useContext(HomePostsContext);
  const [savingPostStatus, setSavingPostStatus] = useState({
    isUploadingImages: false,
    uploadingMediaInfo: {
      category: "",
      UploadingMediaNumber: null,
      totalCategoryLength: null,
      progress: null,
    },
    isSavingPost: false,
    isUploadingImagesError: false,
    isSavingPostError: false,
    status: false,
  });

  function getPathFromUrl(url) {
    const match = url.match(/\/o\/(.*?)\?alt=media/);
    if (match && match[1]) {
      return decodeURIComponent(match[1]);
    }
    return null;
  }

  async function deleteFileByUrl(downloadUrl) {
    const storagePath = getPathFromUrl(downloadUrl);
    if (!storagePath) {
      throw new Error("Invalid download URL");
    }

    const fileRef = ref(storage, storagePath);

    try {
      await deleteObject(fileRef);
    } catch (error) {
      throw new Error(error);
    }
  }

  const mediaUploadPromise = (postUid) => {
    return new Promise(async (resolve, reject) => {
      let uploadedMedia = {
        hall: [],
        bedrooms: [],
        kitchen: [],
        bathroom: [],
        front: [],
      };

      let isEmpty = true;
      Object.entries(mediaToUpload).forEach((category) => {
        if (category[1].toString() !== "") {
          isEmpty = false;
        }
      });
      if (isEmpty) {
        return resolve(uploadedMedia);
      }

      try {
        // Step 1: Process all deletions first
        await Promise.all(
          Object.entries(mediaToUpload).map(async (category) => {
            await Promise.all(
              category[1]?.map(async (media) => {
                if (media?.isDownloaded === true && media?.isRemove === true) {
                  try {
                    await deleteFileByUrl(media.url);
                    console.log(`Deleted existing file: ${media.name}`);
                  } catch (deleteError) {
                    console.warn(
                      `Error deleting file: ${media.name}`,
                      deleteError
                    );
                  }
                }
              })
            );
          })
        );

        // Step 2: After deletions, process uploads
        await Promise.all(
          Object.entries(mediaToUpload).map(async (category) => {
            await Promise.all(
              category[1]?.map(async (media, i) => {
                if (media?.isDownloaded === true) {
                  if (media?.isRemove === true) {
                    return;
                  } else {
                    uploadedMedia[category[0]].push(media.url);
                    return;
                  }
                }

                const uploadTask = uploadBytesResumable(
                  ref(storage, `images/${userUid}/${postUid}/${media.name}`),
                  media,
                  { contentType: "image/jpeg" }
                );

                return new Promise((res, rej) => {
                  uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                      const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      setSavingPostStatus({
                        ...savingPostStatus,
                        isUploadingImages: true,
                        uploadingMediaInfo: {
                          category: category[0],
                          UploadingMediaNumber: i + 1,
                          totalCategoryLength: category[1]?.length,
                          progress: Math.floor(progress),
                        },
                      });
                    },
                    (error) => {
                      rej(error);
                    },
                    () => {
                      getDownloadURL(uploadTask.snapshot.ref).then(
                        (downloadURL) => {
                          uploadedMedia[category[0]].push(downloadURL);
                          res();
                        }
                      );
                    }
                  );
                });
              })
            );
          })
        );

        resolve(uploadedMedia);
      } catch (error) {
        reject(false);
      }
    });
  };

  const handleSavePost = async (reference) => {
    const newPostRef = pendingPostInfo.isPendingPost
      ? pendingPostInfo?.pendingPostRef
      : doc(collection(firestoreDb, "posts"));

    // Avoids duplication of posts that are already saved
    if (!pendingPostInfo.isPendingPost) {
      setPendingPostInfo({
        ...pendingPostInfo,
        isPendingPost: true,
        pendingPostUid: newPostRef.id,
        pendingPostRef: newPostRef,
      });
    }
    setPostRef(newPostRef);
    const contactInfoRef = doc(
      firestoreDb,
      `${newPostRef.path}/secure/contact-info`
    );

    const { postType, ...postInfo } = postPropertyInfo;

    try {
      setSavingPostStatus({ ...savingPostStatus, isUploadingImages: true });
      // throw new Error("test error");
      const uploadedMedia = await mediaUploadPromise(newPostRef.id);
      setSavingPostStatus({ ...savingPostStatus, isUploadingImages: false });

      try {
        setSavingPostStatus({ ...savingPostStatus, isSavingPost: true });
        // throw new Error("test error");
        const uploadPostInfo = {
          postTime: isEditPost ? pendingPostInfo?.pendingPostTime : new Date(),
          postUid: newPostRef.id,
          ownerUid: userUid,
          postType,
          ownerActions: isEditPost
            ? pendingPostInfo?.pendingPostOwnerActions
            : [],
          paymentStatus: isEditPost ? "success" : "pending",
          postStatus: POST_STATUS.IN_REVIEW,
          postInfo,
          media: uploadedMedia,
        };

        const uploadContactInfo = {
          ownerContactNumber:
            postType === "direct-calling" ? contactNumber : null,
          ownerUid: userUid,
        };

        const newPostPromise = pendingPostInfo.isPendingPost
          ? updateDoc(newPostRef, uploadPostInfo)
          : setDoc(newPostRef, uploadPostInfo);

        const contactInfoPromise = pendingPostInfo.isPendingPost
          ? updateDoc(contactInfoRef, uploadContactInfo)
          : setDoc(contactInfoRef, uploadContactInfo);

        await Promise.all([newPostPromise, contactInfoPromise]);

        if (isEditPost) {
          setTriggerMyPosts(!triggerMyPosts);
          setTriggerHomePosts(!triggerHomePosts);
          navigate(`/view-property/${newPostRef.id}`, {
            replace: true,
          });
          window?.history?.state?.idx > 0 ? navigate(-1) : navigate("/");
          setSavingPostStatus({
            ...savingPostStatus,
            isSavingPost: false,
          });
          return;
        }

        setSavingPostStatus({
          ...savingPostStatus,
          isSavingPost: false,
          status: reference === "SAVE_CONTINUE_POST" ? true : false,
        });
      } catch (err) {
        console.error(
          "err while saving post - ListProperty.js - mylogs :",
          err
        );
        setSavingPostStatus({
          ...savingPostStatus,
          isSavingPost: false,
          isSavingPostError: true,
        });
      }
    } catch (err) {
      console.error(
        "err while uploading images - ListProperty.js - mylogs :",
        err
      );
      setSavingPostStatus({
        ...savingPostStatus,
        isUploadingImages: false,
        isUploadingImagesError: true,
      });
    }
  };

  return { savingPostStatus, postRef, setSavingPostStatus, handleSavePost };
};
