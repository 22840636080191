import { Box, List, Stack, Tab } from "@mui/material";
import { Spacer } from "../components/Spacer";
import { primaryBgColor } from "../utils/color";
import { useContext, useEffect, useState } from "react";
import { MainPropertyCard } from "../components/MainPropertyCard";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { MyPostsHeader } from "../components/my-posts-header";
import { TextLoader } from "../components/text-loader";
import { ListPropertyScreen } from "../components/list-property-screen";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export const MyPosts = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const queryTabValue = queryParams.get("tabValue");

  const { myPostsRes, setTriggerMyPostsRes } = useContext(MyPostsContext);

  const [tabValue, setTabValue] = useState(queryTabValue || "1");
  const [disableHandlers, setDisableHandlers] = useState(false);

  const handlers = useSwipeable({
    delta: 20,
    onSwipedLeft: () =>
      !disableHandlers &&
      parseInt(tabValue, 10) < 2 &&
      setTabValue(String(parseInt(tabValue, 10) + 1)),
    onSwipedRight: () =>
      !disableHandlers &&
      parseInt(tabValue, 10) > 1 &&
      setTabValue(String(parseInt(tabValue, 10) - 1)),
  });

  // To trigger myPostsRes when the user navigates to this page
  useEffect(() => {
    setTriggerMyPostsRes(true);
  }, []);

  useEffect(() => {
    navigate(`${window.location.pathname}?tabValue=${tabValue}`, {
      replace: true,
    });
  }, [tabValue]);

  if (myPostsRes?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (myPostsRes?.isError) {
    return <SomethingWentWrongScreen />;
  }

  if (myPostsRes?.myPostsData?.length === 0) {
    return (
      <>
        <MyPostsHeader />
        <ListPropertyScreen />
      </>
    );
  }

  return (
    <>
      <Box>
        <MyPostsHeader />
        <Box sx={{ width: "100%", height: "100vh" }} {...handlers}>
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                position: "sticky",
                top: 50,
                zIndex: 99,
                backgroundColor: "white",
              }}
            >
              <TabList
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="fullWidth"
              >
                <Tab label="Active" value="1" />
                <Tab label="Archive" value="2" />
              </TabList>
            </Box>
            <Spacer space={20} />
            <TabPanel value="1" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  <Stack spacing={3} sx={{ paddingX: "12px" }}>
                    {myPostsRes?.myPostsData?.activePosts?.map(
                      (postData, i) => (
                        <MainPropertyCard
                          key={i}
                          postData={postData}
                          isOwnerNavigatedFromMyPosts={true}
                          setDisableHandlers={setDisableHandlers}
                        />
                      )
                    )}
                  </Stack>
                </List>
              </Box>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px" }}>
              <Box>
                <List sx={{ paddingY: "0px" }}>
                  <Stack spacing={3} sx={{ paddingX: "12px" }}>
                    {myPostsRes?.myPostsData?.archivePosts?.map(
                      (postData, i) => (
                        <MainPropertyCard
                          key={i}
                          postData={postData}
                          isOwnerNavigatedFromMyPosts={true}
                          setDisableHandlers={setDisableHandlers}
                        />
                      )
                    )}
                  </Stack>
                </List>
              </Box>
            </TabPanel>
            <Spacer space={20} />
          </TabContext>
        </Box>
      </Box>
    </>
  );
};
