import { Box, Card, Skeleton, Stack } from "@mui/material";
import { Spacer } from "./Spacer";

export const DashboardLoader = () => {
  return (
    <>
      <Box sx={{ paddingLeft: "10px" }}>
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "2.5rem", width: "80%" }}
        />
        <Spacer space={8} />
        <Stack
          direction="row"
          spacing={1}
          className="scrollable-content"
          sx={{ overflowX: "scroll" }}
        >
          {Array.from({ length: 3 }).map((_, i) => (
            <SkeletonMiniCard key={i} />
          ))}
        </Stack>
      </Box>

      <Spacer space={16} />

      <Box sx={{ paddingX: "10px" }}>
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "2.5rem", width: "83%" }}
        />
        <Spacer space={8} />
        <Stack spacing={2.5}>
          {Array.from({ length: 3 }).map((_, i) => (
            <SkeletonCard key={i} />
          ))}
        </Stack>
      </Box>
    </>
  );
};

export const SkeletonCard = () => {
  return (
    <Card>
      <Skeleton animation="wave" variant="rounded" width="100%" height={140} />
      <Box paddingX={1} padding={1.5}>
        <Stack direction="row" justifyContent="space-between">
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1.5rem", width: "60%" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1.5rem", width: "20%" }}
          />
        </Stack>

        <Spacer space={5} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "0.2rem" }} />
        <Spacer space={5} />

        <Stack direction="row" justifyContent="space-between">
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1.2rem", width: "60%" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1.2rem", width: "20%" }}
          />
        </Stack>
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: "75%" }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: "75%" }}
        />
      </Box>
    </Card>
  );
};

const SkeletonMiniCard = () => {
  return (
    <Stack spacing={0.2} sx={{ paddingRight: "10px" }}>
      <Skeleton animation="wave" variant="rounded" width={240} height={130} />
      <Stack direction="row" justifyContent="space-between">
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1.2rem", width: "60%" }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1.2rem", width: "20%" }}
        />
      </Stack>
      <Skeleton
        animation="wave"
        variant="text"
        sx={{ fontSize: "1.2rem", width: "75%" }}
      />
    </Stack>
  );
};
