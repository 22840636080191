import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";
import { TagsInput } from "react-tag-input-component";
import { FieldHeading } from "../pages/list-property";
import { removingDigitsIndicatorsStyle } from "../style/global-style";
import DatePicker from "react-datepicker";
import {
  CustomInput,
  PostTypeChip,
  PreferenceChip,
} from "./info-tab-list-property";
import { CurrencyRupee, Info } from "@mui/icons-material";
import { useState } from "react";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { convertFirebaseTimestamp } from "../utils/generals";
import { ParkingChip } from "./property-info-tab-list-property";

export const DetailsTabListProperty = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  isEditPost,
}) => {
  const [postTypeInfoModal, setPostTypeInfoModal] = useState({
    isDirectCallingOpen: false,
    isRequestSubmissionOpen: false,
  });

  return (
    <>
      <TitleTextOkayModal
        value="isDirectCallingOpen"
        state={postTypeInfoModal}
        setState={setPostTypeInfoModal}
        title="Direct Calling"
        text="People will directly call you if they are interested in your property."
      />
      <TitleTextOkayModal
        value="isRequestSubmissionOpen"
        state={postTypeInfoModal}
        setState={setPostTypeInfoModal}
        title="Request Submission"
        text="People will submit a request form having basic details to you if they are interested in your property. Once you approve the request then only you both can contact each other."
      />
      <TitleTextOkayModal
        value="isDirectCallingOpen"
        state={postTypeInfoModal}
        setState={setPostTypeInfoModal}
        title="Direct Calling"
        text="People will directly call you if they are interested in your property."
      />
      <TitleTextOkayModal
        value="isRequestSubmissionOpen"
        state={postTypeInfoModal}
        setState={setPostTypeInfoModal}
        title="Request Submission"
        text="People will submit a request form having basic details to you if they are interested in your property. Once you approve the request then only you both can contact each other."
      />
      <Box>
        <Stack spacing={1.5}>
          <FieldHeading heading="Post Type" isRequired />
          <Stack direction="row" spacing={3} alignItems="center">
            <Box sx={{ position: "relative" }}>
              <PostTypeChip
                isEditPost={isEditPost}
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Direct Calling"
                value="direct-calling"
              />
              {!isEditPost && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-6px",
                    right: "-20px",
                    height: "0px",
                  }}
                  color="black"
                  onClick={() =>
                    setPostTypeInfoModal({
                      ...postTypeInfoModal,
                      isDirectCallingOpen: true,
                    })
                  }
                >
                  <Info
                    sx={{ fontSize: "26px", color: `${primaryColor[800]}` }}
                  />
                </IconButton>
              )}
            </Box>
            <Box sx={{ position: "relative" }}>
              <PostTypeChip
                isEditPost={isEditPost}
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Request Submission"
                value="request-submission"
              />
              {!isEditPost && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-6px",
                    right: "-20px",
                    height: "0px",
                  }}
                  color="inherit"
                  disabled={isEditPost}
                  onClick={() =>
                    setPostTypeInfoModal({
                      ...postTypeInfoModal,
                      isRequestSubmissionOpen: true,
                    })
                  }
                >
                  <Info
                    sx={{ fontSize: "26px", color: `${primaryColor[800]}` }}
                  />
                </IconButton>
              )}
            </Box>
          </Stack>
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <Stack>
            <FieldHeading heading="Preferences" isRequired />
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                color: `${grey[700]}`,
              }}
            >
              You can select multiple preference
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.2}>
            <PreferenceChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Family"
              value="Family"
            />
            <PreferenceChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Men Only"
              value="Men Only"
            />
            <PreferenceChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="Female Only"
              value="Female Only"
            />
          </Stack>
        </Stack>
        <Spacer space={30} />
        <Stack spacing={2} direction="row" alignItems="center">
          <FieldHeading heading="Area" isRequired />
          <OutlinedInput
            placeholder="#"
            type="number"
            endAdornment={<InputAdornment position="end">Sqft</InputAdornment>}
            value={postPropertyInfo?.area}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length <= 4) {
                postPropertyInfoDispatch({
                  filter: "area",
                  value,
                });
              }
            }}
            sx={{
              ...removingDigitsIndicatorsStyle,
              width: "36%",
              height: "34px",
              // backgroundColor: "white",
              // "& .MuiOutlinedInput-notchedOutline": {
              //   borderColor: "black",
              // },
            }}
          />
        </Stack>
        <Spacer space={30} />
        <Stack spacing={1} direction="row" alignItems="center">
          <FieldHeading heading="Available From" isRequired />
          <Box height="30px">
            <DatePicker
              showIcon
              dateFormat="dd/MM/yyyy"
              selected={convertFirebaseTimestamp(
                postPropertyInfo?.availableFrom
              )}
              onChange={(date) =>
                postPropertyInfoDispatch({
                  filter: "availableFrom",
                  value: date,
                })
              }
              disabledKeyboardNavigation={false}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              customInput={<CustomInput />}
            />
          </Box>
        </Stack>
        <Spacer space={25} />
        <Stack spacing={1}>
          <FieldHeading heading="BHK" isRequired />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="1"
              value="1"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="2"
              value="2"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="3"
              value="3"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="4"
              value="4"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="5"
              value="5"
            />
            <BhkChip
              postPropertyInfo={postPropertyInfo}
              postPropertyInfoDispatch={postPropertyInfoDispatch}
              label="5+"
              value="5+"
            />
          </Box>
        </Stack>
        <Spacer space={20} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack spacing={1}>
            <FieldHeading heading="Parking" />
            <Stack direction="row" spacing={2}>
              <ParkingChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Bike"
                value1="bike"
                value2="car"
              />
              <ParkingChip
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                label="Car"
                value1="car"
                value2="bike"
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FieldHeading heading="Lift" />
            <Switch
              checked={postPropertyInfo?.lift}
              onChange={() =>
                postPropertyInfoDispatch({
                  filter: "lift",
                  value: !postPropertyInfo?.lift,
                })
              }
            />
          </Stack>
        </Stack>
        <Spacer space={20} />
        <Stack spacing={1}>
          <FieldHeading heading="Your Name" isRequired />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            inputProps={{ maxLength: 24 }}
            placeholder="Your full name"
            sx={{
              ...textInputFieldStyle,
            }}
            value={postPropertyInfo?.fullName}
            onChange={(event) =>
              postPropertyInfoDispatch({
                filter: "fullName",
                value: event.target.value,
              })
            }
          />
        </Stack>
        <Spacer space={15} />
        <Stack spacing={1}>
          <Stack>
            <FieldHeading heading="Rent" isRequired />
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                color: `${grey[700]}`,
              }}
            >
              Allowed rent range 5,000 to 1,00,000 per month
            </Typography>
          </Stack>
          <OutlinedInput
            disabled={isEditPost}
            type="text"
            size="small"
            variant="outlined"
            placeholder="#"
            startAdornment={
              <InputAdornment position="start">
                <CurrencyRupee
                  sx={{
                    fontSize: "20px",
                    // color: primaryColor[800],
                    padding: "2px 0px 0px 0px",
                  }}
                />
                {/* <Typography color="black">Rs</Typography> */}
              </InputAdornment>
            }
            sx={{
              ...removingDigitsIndicatorsStyle,
              // width: "42%",
              height: "38px",
            }}
            value={postPropertyInfo?.montlyRent?.toLocaleString("en-IN") || ""}
            onChange={(event) => {
              let value = event.target.value.replace(/,/g, "");
              if (value.length <= 5) {
                const numericValue = parseInt(value, 10);
                postPropertyInfoDispatch({
                  filter: "montlyRent",
                  value: isNaN(numericValue) ? "" : numericValue,
                });
              }
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Stack>
        <Spacer space={15} />
        <Stack spacing={1}>
          <Stack>
            <FieldHeading heading="Security" isRequired />
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                color: `${grey[700]}`,
              }}
            >
              Security to be deposited
            </Typography>
          </Stack>
          <OutlinedInput
            type="text"
            size="small"
            variant="outlined"
            placeholder="#"
            startAdornment={
              <InputAdornment position="start">
                <CurrencyRupee
                  sx={{
                    fontSize: "20px",
                    // color: primaryColor[800],
                    padding: "2px 0px 0px 0px",
                  }}
                />
                {/* <Typography color="black">Rs</Typography> */}
              </InputAdornment>
            }
            sx={{
              ...removingDigitsIndicatorsStyle,
              // width: "42%",
              height: "38px",
            }}
            value={postPropertyInfo?.security?.toLocaleString("en-IN") || ""}
            onChange={(event) => {
              let value = event.target.value.replace(/,/g, "");
              if (value.length <= 5) {
                const numericValue = parseInt(value, 10);
                postPropertyInfoDispatch({
                  filter: "security",
                  value: isNaN(numericValue) ? "" : numericValue,
                });
              }
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Stack>
        <Spacer space={15} />
        <Stack spacing={1}>
          <Stack>
            <FieldHeading heading="Maintenance Charge" />
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "italic",
                color: `${grey[700]}`,
              }}
            >
              If any, monthly maintenance charges
            </Typography>
          </Stack>
          <OutlinedInput
            type="text"
            size="small"
            variant="outlined"
            placeholder="#"
            startAdornment={
              <InputAdornment position="start">
                <CurrencyRupee
                  sx={{
                    fontSize: "20px",
                    // color: primaryColor[800],
                    padding: "2px 0px 0px 0px",
                  }}
                />
                {/* <Typography color="black">Rs</Typography> */}
              </InputAdornment>
            }
            sx={{
              ...removingDigitsIndicatorsStyle,
              // width: "42%",
              height: "38px",
            }}
            value={
              postPropertyInfo?.maintenanceCharge?.toLocaleString("en-IN") || ""
            }
            onChange={(event) => {
              let value = event.target.value.replace(/,/g, "");
              if (value.length <= 5) {
                const numericValue = parseInt(value, 10);
                postPropertyInfoDispatch({
                  filter: "maintenanceCharge",
                  value: isNaN(numericValue) ? "" : numericValue,
                });
              }
            }}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Stack>
      </Box>
      <Spacer space={120} />
    </>
  );
};

export const BhkChip = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
  label,
  value,
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      variant={postPropertyInfo?.bhk === value ? "filled" : "outlined"}
      onClick={() =>
        postPropertyInfoDispatch({
          filter: "bhk",
          value: `${postPropertyInfo?.bhk === value ? "" : value}`,
        })
      }
      sx={{
        color: `${postPropertyInfo?.bhk === value ? "white" : grey[900]}`,
        backgroundColor: `${postPropertyInfo?.bhk === value ? "" : "white"}`,
        borderColor: `${grey[900]}`,
      }}
    />
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "black",
  //   },
  // },
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
};
