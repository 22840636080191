import {
  and,
  collection,
  getDocs,
  orderBy,
  query,
  where,
  startAt,
  endAt,
} from "@firebase/firestore";
import { async } from "@firebase/util";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { distanceBetween, geohashQueryBounds } from "geofire-common";

export const FilterResultsContext = createContext();

export const FilterResultsContextProvider = ({ children }) => {
  const [filterResultsRes, setFilterResultsRes] = useState({
    isError: false,
    isLoading: false,
    filterResultsData: null,
  });
  const [mainFilterInfoProvider, setMainFilterInfoProvider] = useState(null);

  useEffect(() => {
    setFilterResultsRes({
      isError: false,
      isLoading: false,
      filterResultsData: null,
    });
    if (mainFilterInfoProvider) {
      setFilterResultsRes({
        isError: false,
        isLoading: true,
        filterResultsData: null,
      });
      (async () => {
        try {
          const center = [
            mainFilterInfoProvider?.geoCoordinates?.lat,
            mainFilterInfoProvider?.geoCoordinates?.lng,
          ];
          const radiusInM = (mainFilterInfoProvider?.withinRadius / 10) * 1000;

          // Generate geohash query bounds for the search radius
          const bounds = geohashQueryBounds(center, radiusInM);
          const promises = [];

          // Create Firestore queries for each bound
          for (const b of bounds) {
            let q = query(
              collection(firestoreDb, "posts"),
              where("postStatus", "==", "active"),
              where("paymentStatus", "==", "success"),
              orderBy("postInfo.address.geoCoordinates.geohash"),
              startAt(b[0]),
              endAt(b[1])
            );

            promises.push(getDocs(q));
          }

          // Collect all the query results into a single list
          const snapshots = await Promise.all(promises);

          const matchingDocs = [];
          for (const snap of snapshots) {
            for (const doc of snap.docs) {
              const lat = doc.get("postInfo.address.geoCoordinates.lat");
              const lng = doc.get("postInfo.address.geoCoordinates.lng");

              // Filter out false positives caused by geohash precision
              const distanceInKm = distanceBetween([lat, lng], center);
              const distanceInM = distanceInKm * 1000;

              if (distanceInM <= radiusInM) {
                matchingDocs.push({
                  doc: doc.data(),
                  distanceInM,
                });
              }
            }
          }

          const { housesPriceRange, bhk } = mainFilterInfoProvider;
          const [minPrice, maxPrice] = housesPriceRange;

          // Filter by montlyRent range and BHK
          const filteredDocs = matchingDocs.filter(({ doc }) => {
            const { montlyRent, bhk: docBhk } = doc.postInfo;
            const withinMonthlyRent =
              montlyRent >= minPrice && montlyRent <= maxPrice;

            return withinMonthlyRent && (!bhk.length || bhk.includes(docBhk));
          });

          // Sort the matching documents by distance
          // filteredDocs.sort((a, b) => a.distanceInM - b.distanceInM);

          // Sort based on mainFilterInfoProvider.sortBy
          if (mainFilterInfoProvider.sortBy === "post:asc") {
            filteredDocs.sort((a, b) => a.doc.postTime - b.doc.postTime);
          } else if (mainFilterInfoProvider.sortBy === "price:asc") {
            filteredDocs.sort(
              (a, b) => a.doc.postInfo.montlyRent - b.doc.postInfo.montlyRent
            );
          } else if (mainFilterInfoProvider.sortBy === "price:desc") {
            filteredDocs.sort(
              (a, b) => b.doc.postInfo.montlyRent - a.doc.postInfo.montlyRent
            );
          }

          // Prepare the final results
          const filterResultsRes = filteredDocs.map(({ doc }) => doc);

          // Update the state with the results
          setFilterResultsRes({
            isError: false,
            isLoading: false,
            filterResultsData: filterResultsRes,
          });
        } catch (err) {
          console.error("Error during combined query:", err);
          setFilterResultsRes({
            isError: true,
            isLoading: false,
            filterResultsData: null,
          });
        }
      })();
    }
  }, [mainFilterInfoProvider]);

  return (
    <>
      <FilterResultsContext.Provider
        value={{
          filterResultsRes,
          mainFilterInfoProvider,
          setMainFilterInfoProvider,
        }}
      >
        {children}
      </FilterResultsContext.Provider>
    </>
  );
};
