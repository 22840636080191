import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { primaryColor } from "../utils/color";

export const PaymentReceiptHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        // bgcolor={primaryColor[800]}
        bgcolor="white"
        sx={{ p: "5px", position: "sticky", top: "0", zIndex: "5" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            color="inherit"
            onClick={() =>
              window?.history?.state?.idx > 0 ? navigate(-1) : navigate("/")
            }
          >
            <ArrowBack sx={{ color: primaryColor[800] }} />
          </IconButton>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color="black"
              fontFamily="Ultra, serif"
            >
              Payment
            </Typography>
            <Typography
              fontSize="20px"
              fontWeight="medium"
              color={primaryColor[800]}
              fontFamily="Ultra, serif"
            >
              Details
            </Typography>
          </Stack>
          <Box sx={{ width: "10px" }}></Box>
        </Stack>
      </Box>
    </>
  );
};
