import { useEffect, useState } from "react";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuDrawer } from "./MenuDrawer";
import { primaryColor } from "../utils/color";
import { green, grey, orange, yellow } from "@mui/material/colors";
import { Notifications } from "@mui/icons-material";
import { UseNotificationInfo } from "../hooks/use-notification-info";
import { TitleTextActionModal } from "../modals/title-text-action";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { isAndroid } from "react-device-detect";
import { isApp } from "../utils/isApp";
import { Spacer } from "./Spacer";

export const MainHeader = ({
  user,
  setMainFilterInfoProvider,
  disableHandlers,
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const {
    notificationInfo,
    setNotificationInfo,
    requestNotificationsPermission,
  } = UseNotificationInfo(
    user?.userInfo?.uid,
    user?.hasVisitedToday,
    user?.userData?.fcmToken
  );

  const shownNotificationIcon =
    isAndroid &&
    isApp() &&
    user?.isUserSignedIn &&
    notificationInfo.status !== "granted";

  return (
    <>
      <TitleTextActionModal
        value="isNotificationEnableModalOpen"
        state={notificationInfo}
        setState={setNotificationInfo}
        title="Notifications"
        text="Stay updated with real-time updates. Get alerts when your requests are approved or rejected, or when you receive a new request for your post. Enable notifications to ensure you don't miss anything important."
        actionText="Enable"
        actionFunction={requestNotificationsPermission}
      />
      <TitleTextOkayModal
        value="isNotificationEnableInstructionsModalOpen"
        state={notificationInfo}
        setState={setNotificationInfo}
        title="Turn On Notifications"
        text="Please enable notifications by following these steps: Open your device's Settings. Go to Apps & notifications. Select 'KnockMyDoor' from the list. Tap 'Permissions' and enable 'Notifications'."
      />
      <MenuDrawer
        user={user}
        isDrawerOpen={isDrawerOpen}
        disableHandlers={disableHandlers}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <Box
        bgcolor="white"
        sx={{
          p: "5px",
          position: "sticky",
          top: "0",
          zIndex: "99",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            onClick={() => {
              setMainFilterInfoProvider(null);
              setIsDrawerOpen(true);
            }}
          >
            <MenuIcon sx={{ color: primaryColor[800], fontSize: "28px" }} />
          </IconButton>
          <Stack
            // direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-0.4}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0.3}
            >
              <Typography
                fontSize="19px"
                fontWeight="500"
                color={grey[900]}
                fontFamily="Ultra, serif"
              >
                Knock
              </Typography>
              <Typography
                fontSize="17px"
                fontWeight="500"
                color={primaryColor[800]}
                fontFamily="Ultra, serif"
              >
                My
              </Typography>
              <Typography
                fontSize="17px"
                fontWeight="500"
                color={primaryColor[800]}
                fontFamily="Ultra, serif"
              >
                Door
              </Typography>
            </Stack>
            <Typography
              fontSize="12px"
              fontWeight="600"
              color={grey[800]}
              border
              fontFamily="'Roboto Serif', serif"
            >
              Direct Rental Service
            </Typography>
          </Stack>
          <Box sx={{ width: "40px" }}>
            {shownNotificationIcon && (
              <IconButton
                color="inherit"
                onClick={() => {
                  setNotificationInfo({
                    ...notificationInfo,
                    isNotificationEnableModalOpen: true,
                  });
                }}
              >
                <Notifications sx={{ color: "white" }} />
              </IconButton>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};
