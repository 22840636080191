export const isApp = () => {
  // Check if the app is in standalone mode
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }

  // Fallback for iOS devices
  if (window.navigator.standalone) {
    return true;
  }

  return false;
};
