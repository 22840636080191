import { Button, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

export const ScreenBottomButton = ({ text, action, isButtonDisabled }) => {
  return (
    <>
      <Stack alignItems="center">
        <Button
          disabled={isButtonDisabled}
          variant="contained"
          sx={{
            position: "fixed",
            bottom: "30px",
            width: "93%",
            boxShadow: "8",
            "&.Mui-disabled": {
              backgroundColor: grey[300],
              color: grey[600],
            },
          }}
          onClick={action}
        >
          {text}
        </Button>
      </Stack>
    </>
  );
};
