import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { isDevMode } from "../utils/is-dev-mode";
import { Spacer } from "./Spacer";

export const NavigationBar = () => {
  if (!isDevMode()) {
    return;
  }

  return (
    <>
      <Spacer space={16} />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "16px",
          width: "100%",
          backgroundColor: "white",
          position: "fixed",
          bottom: "0px",
          left: "0px",
          right: "0px",
          zIndex: "1000",
        }}
      >
        <Box
          sx={{
            height: "4px",
            width: "35%",
            backgroundColor: grey[500],
            borderRadius: "10px",
          }}
        ></Box>
      </Stack>
    </>
  );
};
