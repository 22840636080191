import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { PlanHeader } from "../components/plan-header";
import faqsContent from "../content/faqs.json";
import { primaryBgColor, primaryColor } from "../utils/color";
import { Spacer } from "../components/Spacer";
import { MainFooter } from "../components/MainFooter";
import { ExpandMore } from "@mui/icons-material";
import { splitStringBasedOnFirstSpace } from "../utils/generals";
import FaqsIllustration from "../illustrations/faqs.svg";

export const FAQs = () => {
  return (
    <>
      <PlanHeader header1="FAQs" header2="" />
      <Spacer space={12} />
      <Box sx={{ bgcolor: `${primaryBgColor}` }}>
        <Box sx={{ marginX: "8px" }}>
          <Stack direction="row" justifyContent="flex-start" spacing={1}>
            <Typography sx={titleStyle}>Frequently</Typography>
            <Typography color={primaryColor[900]} sx={titleStyle}>
              Asked Questions
            </Typography>
          </Stack>
          <Spacer space={12} />
          <Stack alignItems="center" justifyContent="center">
            <img height="200px" alt="" src={FaqsIllustration} />
          </Stack>
          <Spacer space={12} />
          <Typography sx={textStyle}>
            This FAQ is designed to address common questions from both users and
            property owners, providing clear and detailed information to enhance
            their experience with KnockMyDoor. It is organized into sections for
            general inquiries, renters, and owners to ensure easy navigation and
            quick access to relevant information.
          </Typography>
          <Spacer space={18} />
          <Stack spacing={1}>
            {faqsContent?.map((content, index) => {
              return (
                <Box key={index}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Typography sx={titleStyle}>
                      {splitStringBasedOnFirstSpace(content?.title)[0]}
                    </Typography>
                    <Typography color={primaryColor[900]} sx={titleStyle}>
                      {splitStringBasedOnFirstSpace(content?.title)[1]}
                    </Typography>
                  </Stack>
                  <Spacer space={8} />
                  {content?.query?.map((query, index) => {
                    return (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMore sx={{ paddingLeft: "5px" }} />
                          }
                          aria-controls="panel1-content"
                          id={`panel-${index}-header`}
                        >
                          <Typography
                            key={index}
                            sx={{ ...textStyle, fontWeight: "500" }}
                            dangerouslySetInnerHTML={{
                              __html: query?.question,
                            }}
                          ></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            key={index}
                            sx={textStyle}
                            dangerouslySetInnerHTML={{
                              __html: query?.answer,
                            }}
                          ></Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                  <Spacer space={14} />
                </Box>
              );
            })}
          </Stack>
        </Box>
        <Spacer space={16} />
        <MainFooter />
      </Box>
    </>
  );
};

const titleStyle = {
  fontSize: "21px",
  fontWeight: "500",
  fontFamily: "Calistoga, serif",
};

const textStyle = {
  fontSize: "17px",
};
