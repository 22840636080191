import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Outlet } from "react-router-dom";
import { NavigationBar } from "./navigation-bar";

export const Layout = () => {
  return (
    <>
      <Outlet />
      <NavigationBar />
    </>
  );
};
