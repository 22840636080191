import { useState, useRef, useReducer, useEffect } from "react";
import {
  Box,
  IconButton,
  Divider,
  Typography,
  Stack,
  Chip,
  Button,
  Radio,
  Select,
  MenuItem,
  OutlinedInput,
  Slider,
  TextField,
  Fab,
} from "@mui/material";
import { Tune, RestartAlt, Search } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Center } from "./Center";
import { Spacer } from "./Spacer";
import { AnimatePresence, motion } from "framer-motion";
import ActionSheet from "actionsheet-react";
import { MainBgColor, primaryBgColor, primaryColor } from "../utils/color";
import { localities } from "../constants/constants";
import { useSwipeable } from "react-swipeable";
import { GooglePlacesSearchForFilter } from "./google-places-search-for-filter";
import shadows from "@mui/material/styles/shadows";

const initialMainFilterInfo = {
  sortBy: "post:asc",
  bhk: [],
  locality: "",
  withinRadius: 30,
  geoCoordinates: {
    lat: null,
    lng: null,
  },
  housesPriceRange: [6000, 24000],
};

const mainFilterInfoReducer = (mainFilterInfo, action) => {
  switch (action.type) {
    case "reset":
      return initialMainFilterInfo;
    default:
      return { ...mainFilterInfo, [action.filter]: action.value };
  }
};

export const FilterActionSheet = ({
  setMainFilterInfoProvider,
  setDisableHandlers,
}) => {
  const ref = useRef();
  const [filterSytling, setFilterSytling] = useState({ display: "none" });
  const [isLocalitySelectOpen, setIsLocalitySelectOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const inputRef = useRef(null);
  const [isActionSheetAtTop, setIsActionSheetAtTop] = useState(true);
  const options = ["price", "bhk", "locality"];
  const [mainFilterInfo, mainFilterInfoDispatch] = useReducer(
    mainFilterInfoReducer,
    initialMainFilterInfo
  );

  const sortByOption = [
    "Post: Newest",
    "Price: High to Low",
    "Price: Low to High",
  ];

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setIsActionSheetAtTop(scrollTop === 0);
  };

  const handlers = useSwipeable({
    delta: 120,
    onSwipedDown: () => {
      if (isActionSheetAtTop) {
        ref.current.close();
        document.body.style.overflow = "";
        setDisableHandlers(false);
      }
    },
  });

  const handleOnClose = () => {
    document.body.style.overflow = "";
    setDisableHandlers(false);
  };

  const handleReset = () => {
    mainFilterInfoDispatch({
      type: "reset",
    });
    setMainFilterInfoProvider(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleSearch = async () => {
    ref.current.close();
    document.body.style.overflow = "";
    setDisableHandlers(false);
    setMainFilterInfoProvider(mainFilterInfo);
  };

  useEffect(() => {
    const isAtBottom = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const scrollY = window.scrollY;
      return scrollY + windowHeight >= documentHeight - 150;
    };

    const scrolling = () => {
      const scrollTop = window.scrollY;
      if (!isAtBottom() && scrollTop > 175) {
        setFilterSytling({
          position: "fixed",
          bottom: "60px",
          right: "30px",
          zIndex: "99",
        });
      } else {
        setFilterSytling({ display: "none" });
      }
    };

    window.addEventListener("scroll", scrolling);

    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, []);

  useEffect(() => {
    window.history.pushState(
      window?.history?.state,
      null,
      window.location.pathname
    );
    const handlePopstate = (event) => {
      ref.current.close();
      document.body.style.overflow = "";
      setDisableHandlers(false);
      window.history.pushState(
        window?.history?.state,
        null,
        window.location.pathname
      );
    };
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 2000); // Change text every 2 seconds
    return () => clearInterval(interval);
  }, [options.length]);

  return (
    <>
      <Box
        sx={filterSytling}
        onClick={() => {
          ref.current.open();
          document.body.style.overflow = "hidden";
          setDisableHandlers(true);
        }}
      >
        <Fab color="primary">
          <Tune />
        </Fab>
      </Box>

      <ActionSheet
        ref={ref}
        onClose={handleOnClose}
        sheetStyle={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          height: "85%",
          overflowY: "scroll",
          backgroundColor: `${primaryBgColor}`,
        }}
        mouseEnable={false}
        touchEnable={false}
        sheetTransition="transform 0.2s ease-in-out"
        bgTransition="opacity 0.3s ease-in-out, z-index 0.3s ease-in-out"
      >
        <Box
          onScroll={handleScroll}
          {...handlers}
          style={{ height: "100%", overflowY: "auto" }}
        >
          <Box
            sx={{
              bgcolor: "white",
              width: "100%",
              position: "fixed",
              top: "0px",
              zIndex: "9",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              px="8px"
              bgcolor={primaryBgColor}
            >
              <Box></Box>
              <Typography
                fontSize="21px"
                fontWeight="600"
                color={primaryColor[900]}
              >
                Filters
              </Typography>
              <IconButton color="inherit" onClick={handleReset}>
                <RestartAlt sx={{ fontSize: "28px" }} />
              </IconButton>
            </Stack>
            <Divider />
          </Box>
          <Spacer space={40} />
          <Box sx={{ bgcolor: `${MainBgColor}`, overflowY: "scroll" }}>
            <Box sx={{ marginX: "15px" }}>
              <Spacer space={20} />
              <GooglePlacesSearchForFilter
                inputRef={inputRef}
                mainFilterInfo={mainFilterInfo}
                mainFilterInfoDispatch={mainFilterInfoDispatch}
              />
              <Spacer space={15} />
              <Spacer space={15} />
              <Box width="100%">
                <Typography fontWeight="600">Within range</Typography>
                <Typography
                  sx={{
                    color: grey[800],
                    fontStyle: "italic",
                  }}
                >
                  ( in km )
                </Typography>
              </Box>
              <Stack alignItems="flex-start" paddingLeft="140px">
                <Slider
                  disabled={!mainFilterInfo?.locality}
                  value={mainFilterInfo?.withinRadius}
                  onChange={(event, newValue) => {
                    mainFilterInfoDispatch({
                      filter: "withinRadius",
                      value: newValue,
                    });
                  }}
                  valueLabelDisplay="on"
                  step={10}
                  aria-labelledby="input-slider"
                  min={10}
                  max={100}
                  valueLabelFormat={(value) => `${value / 10}`}
                  sx={{
                    height: 8,
                    width: "80%",
                    "& .MuiSlider-thumb": {
                      height: 24,
                      width: 24,
                      backgroundColor: "#fff",
                      border: "2px solid currentColor",
                      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                        boxShadow: "inherit",
                      },
                      "&:before": {
                        display: "none",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      lineHeight: 1.2,
                      fontSize: 18,
                      background: "unset",
                      padding: 0,
                      width: 32,
                      height: 32,
                      borderRadius: "50% 50% 50% 0",
                      backgroundColor: !mainFilterInfo?.locality
                        ? grey[400]
                        : primaryColor[800],
                      transformOrigin: "bottom left",
                      transform:
                        "translate(50%, -100%) rotate(-45deg) scale(0)",
                      "&::before": { display: "none" },
                      "&.MuiSlider-valueLabelOpen": {
                        transform:
                          "translate(50%, -100%) rotate(-45deg) scale(0.75)",
                      },
                      "& > *": {
                        transform: "rotate(45deg)",
                      },
                    },
                  }}
                />
              </Stack>
              <Stack alignItems="flex-start">
                <Typography fontWeight="600">Sort by</Typography>
                <Stack spacing={-1}>
                  <Stack direction="row" alignItems="center">
                    <Radio
                      checked={mainFilterInfo?.sortBy === "post:asc"}
                      onChange={(event) => {
                        mainFilterInfoDispatch({
                          filter: "sortBy",
                          value: event.target.value,
                        });
                      }}
                      value="post:asc"
                    />
                    <Typography>Newest</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Radio
                      checked={mainFilterInfo?.sortBy === "price:desc"}
                      onChange={(event) => {
                        mainFilterInfoDispatch({
                          filter: "sortBy",
                          value: event.target.value,
                        });
                      }}
                      value="price:desc"
                    />
                    <Typography>Price - High To Low</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Radio
                      checked={mainFilterInfo?.sortBy === "price:asc"}
                      onChange={(event) => {
                        mainFilterInfoDispatch({
                          filter: "sortBy",
                          value: event.target.value,
                        });
                      }}
                      value="price:asc"
                    />
                    <Typography>Price - Low To High</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Spacer space={10} />
              <Stack alignItems="flex-start" spacing={1}>
                <Typography fontWeight="600">Bhk</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
                  <Chip
                    label="1"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("1") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("1")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "1")
                          : [...mainFilterInfo.bhk, "1"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("1") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="2"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("2") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("2")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "2")
                          : [...mainFilterInfo.bhk, "2"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("2") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="3"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("3") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("3")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "3")
                          : [...mainFilterInfo.bhk, "3"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("3") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="4"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("4") ? "filled" : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("4")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "4")
                          : [...mainFilterInfo.bhk, "4"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("4") ? "white" : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                  <Chip
                    label="4+"
                    color="primary"
                    variant={
                      mainFilterInfo?.bhk?.includes("4+")
                        ? "filled"
                        : "outlined"
                    }
                    onClick={() =>
                      mainFilterInfoDispatch({
                        filter: "bhk",
                        value: mainFilterInfo.bhk.includes("4+")
                          ? mainFilterInfo.bhk.filter((bhk) => bhk !== "4+")
                          : [...mainFilterInfo.bhk, "4+"],
                      })
                    }
                    sx={{
                      color: `${
                        mainFilterInfo?.bhk?.includes("4+")
                          ? "white"
                          : grey[900]
                      }`,
                      borderColor: `${grey[900]}`,
                    }}
                  />
                </Box>
              </Stack>
              <Spacer space={18} />
              <Stack alignItems="flex-start" spacing={1}>
                <Typography fontWeight="600">Select Rent Range</Typography>
                <Box sx={{ width: "100%" }}>
                  <Slider
                    value={[
                      mainFilterInfo?.housesPriceRange[0] / 500,
                      mainFilterInfo?.housesPriceRange[1] / 500,
                    ]}
                    onChange={(event, newValue) => {
                      mainFilterInfoDispatch({
                        filter: "housesPriceRange",
                        value: [newValue[0] * 500, newValue[1] * 500],
                      });
                    }}
                    aria-labelledby="input-slider"
                    sx={{
                      height: 8,
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "#fff",
                        border: "2px solid currentColor",
                        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                          boxShadow: "inherit",
                        },
                        "&:before": {
                          display: "none",
                        },
                      },
                    }}
                  />
                </Box>
                <Spacer space={3} />
                <Stack direction="row" justifyContent="space-between" px="20px">
                  <TextField
                    label="Min"
                    value={mainFilterInfo?.housesPriceRange[0]}
                    disabled={true}
                    size="small"
                    sx={{
                      width: "40%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        "-webkit-text-fill-color": "black",
                      },
                    }}
                  />
                  <TextField
                    label="Max"
                    value={mainFilterInfo?.housesPriceRange[1]}
                    disabled={true}
                    size="small"
                    sx={{
                      width: "40%",
                      "& .MuiInputBase-input.Mui-disabled": {
                        "-webkit-text-fill-color": "black",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Spacer space={30} />
            </Box>
            <Box
              sx={{
                width: "100%",
                // position: "fixed",
                // bottom: "25px",
                bgcolor: `${primaryBgColor}`,
              }}
            >
              <Center>
                <Button
                  disabled={!mainFilterInfo?.locality}
                  sx={{ width: "90%", borderRadius: "7px" }}
                  variant="contained"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Center>
            </Box>
            <Spacer space={30} />
          </Box>
        </Box>
      </ActionSheet>

      <Box>
        <Center>
          <Chip
            label={
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="center"
              >
                <Typography sx={{ color: "black", fontWeight: "600" }}>
                  Filter by
                </Typography>
                <Typography>
                  <AnimatePresence mode="wait">
                    <motion.span
                      key={index}
                      initial={{ opacity: 0, y: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Typography
                        sx={{
                          color: `${primaryColor[800]}`,
                          fontWeight: "600",
                          width: "65px",
                        }}
                      >
                        "{options[index]}"
                      </Typography>
                    </motion.span>
                  </AnimatePresence>
                </Typography>
              </Stack>
            }
            icon={<Search sx={{ fontSize: "20px", color: "white" }} />}
            variant="outlined"
            sx={filterChipStyle}
            onClick={() => {
              ref.current.open();
              document.body.style.overflow = "hidden";
              setDisableHandlers(true);
            }}
          />
        </Center>
      </Box>
    </>
  );
};

const filterChipStyle = {
  // bgcolor: `${primaryColor[800]}`,
  bgcolor: "white",
  width: "100%",
  height: "35px",
  color: "white",
  border: `1px solid ${primaryColor[800]}`,
  "& .MuiChip-icon": {
    color: "black",
  },
  "& .MuiChip-label": {
    fontSize: "17px",
  },
  "&.MuiChip-clickable:hover": {
    // bgcolor: `${primaryColor[800]}`,
    bgcolor: "white",
  },
};

export const FieldHeading = ({ heading }) => {
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <Typography sx={{ fontWeight: "600" }}>{heading}</Typography>
      <Typography color={primaryColor[900]} sx={{ fontWeight: "500" }}>
        *
      </Typography>
    </Stack>
  );
};

const selectInputFieldStyle = {
  backgroundColor: "white",
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
};
