import { useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryBgColor, primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";
import { isRequiredMedia, mediaLimits } from "../constants/constants";
import { WarningOkayModal } from "../modals/warning-okay";
import { AddPhotoAlternate, Cancel, PhotoLibrary } from "@mui/icons-material";

export const MediaTabListProperty = ({
  media,
  mediaDispatch,
  pendingPostInfo,
}) => {
  const [mediaWarningModalStatus, setMediaWarningModalStatus] = useState({
    isMediaSizeWarningModalOpen: false,
    isMediaLimitWarningModalOpen: false,
  });

  const hallInputRef = useRef(null);
  const bedroomInputRef = useRef(null);
  const kitchenInputRef = useRef(null);
  const bathroomInputRef = useRef(null);
  const frontInputRef = useRef(null);

  const mediaCategories = [
    {
      category: "hall",
      ref: hallInputRef,
      maxMedia: mediaLimits.hall,
      minMedia: 1,
      isRequired: isRequiredMedia.hall,
    },
    {
      category: "bedrooms",
      ref: bedroomInputRef,
      maxMedia: mediaLimits.bedrooms,
      minMedia: 1,
      isRequired: isRequiredMedia.bedrooms,
    },
    {
      category: "kitchen",
      ref: kitchenInputRef,
      maxMedia: mediaLimits.kitchen,
      minMedia: 1,
      isRequired: isRequiredMedia.kitchen,
    },
    {
      category: "bathroom",
      ref: bathroomInputRef,
      maxMedia: mediaLimits.bathroom,
      minMedia: 1,
      isRequired: isRequiredMedia.bathroom,
    },
    {
      category: "front",
      ref: frontInputRef,
      maxMedia: mediaLimits.front,
      minMedia: 0,
      isRequired: isRequiredMedia.front,
    },
  ];

  const addWatermarkToImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      const watermark = new Image();
      watermark.src = require("../images/custom-kmd-logo.png");

      img.onload = () => {
        URL.revokeObjectURL(img.src); // Release object URL to free memory

        const maxCanvasSize = 2048; // Max canvas size for compatibility
        const scale = Math.min(
          1,
          maxCanvasSize / Math.max(img.width, img.height)
        );

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Scale the canvas and draw the image
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        ctx.scale(scale, scale);
        ctx.drawImage(img, 0, 0);

        watermark.onload = () => {
          const watermarkWidth = img.width * 0.1; // 10% of image width
          const watermarkHeight =
            (watermark.height / watermark.width) * watermarkWidth;

          const x = img.width - watermarkWidth - 60; // Position bottom-right
          const y = 60;

          ctx.globalAlpha = 0.5; // Set transparency for watermark
          ctx.drawImage(watermark, x, y, watermarkWidth, watermarkHeight);
          ctx.globalAlpha = 1; // Reset transparency

          canvas.toBlob((blob) => {
            if (blob) {
              const watermarkedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(watermarkedFile);
            } else {
              reject(new Error("Failed to create blob from canvas"));
            }
          }, file.type);
        };

        watermark.onerror = () =>
          reject(new Error("Failed to load watermark image"));
      };

      img.onerror = () => reject(new Error("Failed to load original image"));
    });
  };

  const handleMediaInput = async (event, category) => {
    const uploadedMedia = Array.from(event.target.files);

    // Check if media exceeds category limit
    if (
      uploadedMedia?.length > mediaLimits[category] ||
      media?.uploadedMedia[category].filter((file) => !file.isRemove).length >=
        mediaLimits[category]
    ) {
      setMediaWarningModalStatus({
        ...mediaWarningModalStatus,
        isMediaLimitWarningModalOpen: true,
      });
      return;
    }

    // Check if media size exceeds the limit
    const isMediaSizeLimitExceed = uploadedMedia.some(
      (media) => media.size > 12582912 // 12 MB
    );
    if (isMediaSizeLimitExceed) {
      setMediaWarningModalStatus({
        ...mediaWarningModalStatus,
        isMediaSizeWarningModalOpen: true,
      });
      return;
    }

    // Filter out duplicate media
    const existingMediaFiles = Object.values(media.uploadedMedia)
      .flat()
      .filter((file) => !file.isRemove)
      .map((file) => file.name);

    const uniqueUploadedMedia = uploadedMedia.filter(
      (file) => !existingMediaFiles.includes(file.name)
    );

    if (uniqueUploadedMedia.length === 0) {
      console.log(
        "No new media to upload, duplicates detected or media already removed."
      );
      return;
    }

    // Process each image to add watermark
    // const watermarkedMedia = await Promise.all(
    //   uniqueUploadedMedia.map((file) => addWatermarkToImage(file))
    // );

    // Create object URLs for the new media
    const mediaUrls = uniqueUploadedMedia.map((media) =>
      URL.createObjectURL(media)
    );

    const displayMedia = mediaUrls.map((url, i) => ({
      url,
      name: uniqueUploadedMedia[i].name,
    }));

    mediaDispatch({
      activity: "addMedia",
      type: "uploadedMedia",
      category,
      value: uniqueUploadedMedia,
    });
    mediaDispatch({
      activity: "addMedia",
      type: "displayMedia",
      category,
      value: displayMedia,
    });
  };

  const handleMediaDelete = (removingName, category) => {
    mediaDispatch({
      activity: "removeUploaedMedia",
      type: "uploadedMedia",
      category,
      value: removingName,
    });

    mediaDispatch({
      activity: "removeDisplayMedia",
      type: "displayMedia",
      category,
      value: removingName,
    });
  };

  return (
    <>
      <WarningOkayModal
        value="isMediaSizeWarningModalOpen"
        state={mediaWarningModalStatus}
        setState={setMediaWarningModalStatus}
        warnMsg="Media size should be less than 12 MB."
      />
      <WarningOkayModal
        value="isMediaLimitWarningModalOpen"
        state={mediaWarningModalStatus}
        setState={setMediaWarningModalStatus}
        warnMsg="Maximum upload limits of images crossed for this category."
      />

      <Box>
        {mediaCategories?.map((mediaCategory) => (
          <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                {mediaCategory.category.charAt(0).toUpperCase() +
                  mediaCategory.category.slice(1)}
              </Typography>
              {mediaCategory?.isRequired && (
                <Typography
                  color={primaryColor[900]}
                  sx={{ fontSize: "16px", fontWeight: "500" }}
                >
                  *
                </Typography>
              )}
              <IconButton
                color="inherit"
                onClick={() => {
                  mediaCategory.ref.current.click();
                }}
              >
                <AddPhotoAlternate
                  sx={{ fontSize: "30px", color: `${primaryColor[800]}` }}
                />
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  ref={mediaCategory.ref}
                  onChange={(event) =>
                    handleMediaInput(event, mediaCategory.category)
                  }
                  style={{ display: "none" }}
                />
              </IconButton>
            </Stack>
            <Spacer space={3} />
            <Box>
              <Grid container rowSpacing={1} columnSpacing={3}>
                {media?.displayMedia[mediaCategory.category].length ? (
                  media?.displayMedia[mediaCategory.category]?.map((media) => {
                    return (
                      <>
                        <Grid item xs={6}>
                          <Box sx={{ position: "relative" }}>
                            <img
                              style={{
                                height: "100px",
                                width: "160px",
                                aspectRatio: "16/9",
                                objectFit: "fill",
                                borderRadius: "8px",
                              }}
                              src={media?.url}
                              alt=""
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-2px",
                                left: "124px",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleMediaDelete(
                                    media?.name,
                                    mediaCategory.category
                                  )
                                }
                              >
                                <Cancel
                                  sx={{
                                    color: `${primaryColor[900]}`,
                                    fontSize: "20px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    );
                  })
                ) : (
                  <Grid item xs={6}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: "100px",
                        width: "160px",
                        border: `1px solid ${grey[400]}`,
                        borderRadius: "8px",
                        backgroundColor: "white",
                      }}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <PhotoLibrary
                          sx={{
                            fontSize: "30px",
                            color: `${grey[400]}`,
                            opacity: "0.5",
                          }}
                        />
                        <Stack>
                          <Typography
                            sx={{
                              fontSize: "8px",
                              fontWeight: "500",
                              paddingX: "10px",
                              textAlign: "center",
                              opacity: "0.5",
                            }}
                          >
                            Add images
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px",
                              fontWeight: "500",
                              paddingX: "10px",
                              textAlign: "center",
                              opacity: "0.5",
                            }}
                          >
                            Minimum {mediaCategory?.minMedia}, Maximum{" "}
                            {mediaCategory?.maxMedia}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Spacer space={3} />
            </Box>
          </>
        ))}
        <Spacer space={50} />
      </Box>
    </>
  );
};
