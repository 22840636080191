import { useJsApiLoader } from "@react-google-maps/api";
import { FIREBASE_API_KEY } from "../firebase/firebaseConfig";

export const UseGooglePlace = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: FIREBASE_API_KEY,
    libraries: ["places"],
  });

  return { isLoaded, loadError };
};
