import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  OAuthProvider,
  connectAuthEmulator,
  getAuth,
} from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { isDevMode } from "../utils/is-dev-mode";
import FLAGS from "../flags/flags";
import { isAndroid } from "react-device-detect";

export const FIREBASE_API_KEY = "AIzaSyDRxpGIwoX6s48OQWCbbvIwb9XBoQwRpT0";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "knockmydoor.com",
  projectId: "okhla-homes",
  storageBucket: "okhla-homes.appspot.com",
  messagingSenderId: "368211628037",
  appId: "1:368211628037:web:6735538b73ca3194b6cc6c",
  measurementId: "G-VZNK1NY9RP",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const googleAuth = getAuth(app);
export const firestoreDb = getFirestore(app);
export const storage = getStorage(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const appleAuthProvider = new OAuthProvider("apple.com");
export const messaging = isAndroid ? getMessaging(app) : null; // Firebase Messaging is not supported on iOS browsers.

if (isDevMode() && FLAGS.DEV_MODE) {
  connectAuthEmulator(googleAuth, "http://localhost:9099");
  connectFirestoreEmulator(firestoreDb, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}
