import { useRef } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { geohashForLocation } from "geofire-common";
import { FieldHeading } from "../pages/list-property";
import { primaryColor } from "../utils/color";
import { grey } from "@mui/material/colors";
import { Spacer } from "./Spacer";
import { Search } from "@mui/icons-material";
import { UseGooglePlace } from "../hooks/use-google-place";

export const GooglePlacesSearchForListing = ({
  postPropertyInfo,
  postPropertyInfoDispatch,
}) => {
  const { isLoaded } = UseGooglePlace();

  const searchBoxRef = useRef(null);
  const inputRef = useRef(null);

  const onPlacesChanged = async () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const locationCoordinate = places[0].geometry.location.toJSON();

        const formattedAddress = places[0].formatted_address;

        const addressComponents = places[0].address_components || [];
        const locality = addressComponents[0]?.long_name;

        const state =
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "";

        const pincode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        postPropertyInfoDispatch({
          filter: "address",
          value: {
            ...postPropertyInfo?.address,
            locality: locality,
            fullAddress: formattedAddress,
            state: state,
            pincode: pincode,
            geoCoordinates: {
              geohash: geohashForLocation([
                locationCoordinate.lat,
                locationCoordinate.lng,
              ]),
              lat: locationCoordinate.lat,
              lng: locationCoordinate.lng,
            },
          },
        });

        // Reset the input field
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      } else {
        console.error("No places found.");
      }
    }
  };

  return (
    <Box>
      <Stack spacing={0.8}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Typography
            alignSelf="center"
            sx={{
              fontSize: "19px",
              fontWeight: "600",
            }}
          >
            Search
          </Typography>
          <Typography
            alignSelf="center"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: primaryColor[800],
            }}
          >
            For Locality
          </Typography>
          <Search sx={{ fontSize: "22px", color: primaryColor[800] }} />
        </Stack>
        {isLoaded ? (
          <StandaloneSearchBox
            onLoad={(ref) => {
              searchBoxRef.current = ref;
            }}
            onPlacesChanged={onPlacesChanged}
            options={{
              componentRestrictions: { country: "in" },
            }}
          >
            <TextField
              type="text"
              size="small"
              variant="outlined"
              placeholder="Where is your property located?"
              inputRef={inputRef}
              sx={{
                ...textInputFieldStyle,
              }}
            />
          </StandaloneSearchBox>
        ) : (
          <TextField
            disabled={true}
            type="text"
            size="small"
            variant="outlined"
            placeholder="Search loading..."
            sx={{
              ...textInputFieldStyle,
            }}
          />
        )}
      </Stack>
      <Box>
        <Spacer space={20} />
        <Stack direction="column" spacing={0}>
          <FieldHeading heading="Locality" isRequired />
          <Typography
            sx={{
              fontSize: "16px",
              color: `${grey[800]}`,
            }}
          >
            {postPropertyInfo?.address?.locality}
          </Typography>
        </Stack>
        <Spacer space={10} />
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <Typography>
            <FieldHeading heading="Address" isRequired />
            <Typography
              sx={{
                fontSize: "16px",
                color: `${grey[800]}`,
              }}
            >
              {postPropertyInfo?.address?.fullAddress}
            </Typography>
          </Typography>
        </Stack>
        <Spacer space={20} />
      </Box>
    </Box>
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    // "& fieldset": {
    //   borderColor: "black",
    // },
  },
};
