import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import { Edit, Email, NavigateNext, Phone } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";

export const TopSectionMenuDrawer = ({ user }) => {
  const navigate = useNavigate();
  const { userInfo, isUserSignedIn, userData } = user;

  return (
    <>
      <Box>
        {isUserSignedIn ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              paddingY: "12px",
              paddingX: "15px",
              // bgcolor: primaryColor[800],
              bgcolor: "white",
            }}
          >
            <IconButton
              color="inherit"
              sx={{ paddingLeft: "0px" }}
              component={Link}
              to="/account"
            >
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: primaryColor[800],
                  color: "white",
                }}
                alt=""
                src={userInfo?.photoURL}
              />
            </IconButton>
            <Stack spacing={-0.8} onClick={() => navigate("/account")}>
              <Stack direction="row" spacing={1}>
                <Typography
                  fontSize="18px"
                  fontFamily="Neuton, serif"
                  fontWeight="600"
                >
                  Hello,
                </Typography>
                <Typography
                  fontSize="18px"
                  fontFamily="Neuton, serif"
                  fontWeight="600"
                >
                  {userInfo?.displayName?.split(" ")[0]}
                </Typography>
              </Stack>
              <Spacer space={9} />
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Email
                  sx={{
                    fontSize: "16px",
                    paddingTop: "3px",
                    color: primaryColor[800],
                  }}
                />
                <Typography
                  fontSize="16px"
                  fontFamily="Neuton, serif"
                  color={grey[900]}
                >
                  {userInfo?.email}
                </Typography>
              </Stack>
              <Spacer space={8} />
              {userData?.contactNumber ? (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Phone
                    sx={{
                      fontSize: "16px",
                      color: primaryColor[800],
                    }}
                  />
                  <Typography
                    fontSize="16px"
                    fontFamily="Neuton, serif"
                    color={grey[900]}
                  >
                    +91 {userData?.contactNumber}
                  </Typography>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography
                    fontSize="16px"
                    fontFamily="Neuton, serif"
                    color={grey[900]}
                  >
                    Add contact number
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{
              paddingY: "4px",
              paddingX: "10px",
              bgcolor: "white",
            }}
          >
            <IconButton
              color="inherit"
              component={Link}
              to="/login"
              sx={{ paddingLeft: "0px" }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: primaryColor[800],
                  color: "white",
                }}
                alt=""
                src={userInfo?.photoURL}
              />
            </IconButton>
            <Stack
              direction="row"
              alignItems="center"
              component={Link}
              to="/login"
              sx={{ textDecoration: "none" }}
            >
              <Button
                size="large"
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  fontFamily: "Neuton, serif",
                  color: "black",
                }}
              >
                Login
              </Button>
              <NavigateNext
                sx={{
                  fontSize: "30px",
                  marginTop: "3px",
                  color: "black",
                }}
              />
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  );
};
