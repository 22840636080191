import { Box, Button, Stack, Typography } from "@mui/material";
import { primaryColor } from "../utils/color";
import { APPLE_STORE_URL, PLAYSTORE_URL } from "../constants/constants";
import { isDevMode } from "../utils/is-dev-mode";
import { isIOS } from "react-device-detect";
import { isApp } from "../utils/isApp";
import GooglePlayIcon from "../icons/google-play.svg";
import AppleStoreIcon from "../icons/apple-store.svg";

export const AppInstallBanner = () => {
  if (isApp() || isDevMode()) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ paddingY: "6px", paddingX: "8px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <img
              style={{
                width: "46px",
                height: "42px",
                borderRadius: "10px",
                border: `1.5px solid ${primaryColor[800]}`,
              }}
              alt=""
              src={`${process.env.PUBLIC_URL}/images/light-kmd-logo.png`}
            />
          </Box>
          <Stack spacing={-0.5}>
            <Typography
              sx={{
                color: primaryColor[800],
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Neuton, serif",
              }}
            >
              {isIOS ? "Switch To App store App" : "Switch To Playstore App"}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              For Better Experience
            </Typography>
          </Stack>
          <Button
            variant="contained"
            endIcon={
              isIOS ? (
                <img width="20px" height="20px" alt="" src={AppleStoreIcon} />
              ) : (
                <img width="15px" height="15px" alt="" src={GooglePlayIcon} />
              )
            }
            onClick={() =>
              isIOS
                ? window.open(APPLE_STORE_URL, "_self")
                : window.open(PLAYSTORE_URL, "_self")
            }
            sx={{
              paddingY: "5px",
              paddingX: "10px",
            }}
          >
            Install
          </Button>
        </Stack>
      </Box>
    </>
  );
};
