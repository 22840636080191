import { useEffect, useRef } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import { Search } from "@mui/icons-material";
import { UseGooglePlace } from "../hooks/use-google-place";

export const GooglePlacesSearchForFilter = ({
  inputRef,
  mainFilterInfo,
  mainFilterInfoDispatch,
}) => {
  const { isLoaded } = UseGooglePlace();

  const searchBoxRef = useRef(null);

  const onPlacesChanged = async () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const locationCoordinate = places[0].geometry.location.toJSON();

        const addressComponents = places[0].address_components || [];
        const locality = addressComponents[0]?.long_name;

        const formattedAddress = places[0].formatted_address;

        mainFilterInfoDispatch({
          filter: "locality",
          value: locality,
        });

        mainFilterInfoDispatch({
          filter: "geoCoordinates",
          value: locationCoordinate,
        });
      } else {
        console.error("No places found.");
      }
    }
  };

  const handleOnBlur = () => {
    inputRef.current.value = "";
    mainFilterInfoDispatch({
      filter: "locality",
      value: "",
    });
    mainFilterInfoDispatch({
      filter: "geoCoordinates",
      value: {
        lat: null,
        lng: null,
      },
    });
  };

  useEffect(() => {
    if (mainFilterInfo?.locality) {
      inputRef.current.value = mainFilterInfo?.locality;
    }
  }, [mainFilterInfo?.locality]);

  return (
    <Box>
      <Stack spacing={0.8}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Typography
            alignSelf="center"
            sx={{
              fontSize: "19px",
              fontWeight: "600",
            }}
          >
            Search
          </Typography>
          <Typography
            alignSelf="center"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: primaryColor[800],
            }}
          >
            For Locality
          </Typography>
          <Search sx={{ fontSize: "22px", color: primaryColor[800] }} />
        </Stack>
        {/* <Typography fontWeight="600">Search For Locality</Typography> */}
        {isLoaded ? (
          <StandaloneSearchBox
            onLoad={(ref) => {
              searchBoxRef.current = ref;
            }}
            onPlacesChanged={onPlacesChanged}
            options={{
              componentRestrictions: { country: "in" },
            }}
          >
            <TextField
              type="text"
              size="small"
              variant="outlined"
              placeholder="Search locality"
              inputRef={inputRef}
              onBlur={handleOnBlur}
              sx={{
                ...textInputFieldStyle,
              }}
            />
          </StandaloneSearchBox>
        ) : (
          <TextField
            disabled={true}
            type="text"
            size="small"
            variant="outlined"
            placeholder="Search loading..."
            sx={{
              ...textInputFieldStyle,
            }}
          />
        )}
      </Stack>
      {/* <Box>
        <Spacer space={20} />
        <Stack direction="column" spacing={0}>
          <Stack direction="row" justifyContent="flex-start" spacing={0.2}>
            <Typography fontWeight="600">Properties near</Typography>
            <Typography
              color={primaryColor[900]}
              sx={{ fontSize: "16px", fontWeight: "500" }}
            >
              *
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: "16px",
              color: "black",
            }}
          >
            {mainFilterInfo?.locality}
          </Typography>
        </Stack>
      </Box> */}
    </Box>
  );
};

const textInputFieldStyle = {
  backgroundColor: "white",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    height: "36px",
    borderRadius: "10px",
    // "& fieldset": {
    //   borderColor: "black",
    // },
  },
};
