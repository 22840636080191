import { Navigate, Outlet } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContextProvider";
import { useContext } from "react";
import { setSignInRedirectPath } from "../utils/generals";
import { NavigationBar } from "./navigation-bar";

export const ProtectedRoutes = () => {
  const { user } = useContext(ProfileContext);
  const { isUserSignedIn } = user;
  if (!isUserSignedIn) {
    setSignInRedirectPath();
  }

  return isUserSignedIn ? (
    <>
      <Outlet />
      <NavigationBar />
    </>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};
