import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json"; // Import English locale

import { Carousel } from "react-responsive-carousel";
import { POST_STATUS, mediaSorted } from "../constants/constants";
import { primaryColor } from "../utils/color";
import { Spacer } from "./Spacer";
import {
  addNumberSuffix,
  convertFirebaseTimestamp,
  getDisplayFullAddress,
  getIntegratedFullAddress,
  getPostTypeValues,
  trimString,
} from "../utils/generals";
import { TitleTextOkayModal } from "../modals/title-text-okay";
import { useState } from "react";
import {
  BorderColor,
  ContentCopy,
  CurrencyRupee,
  Done,
  Info,
} from "@mui/icons-material";
import { TextOkayModal } from "../modals/text-okay";
import {
  SERVICE_CHARGE_FREE_DESCRIPTION,
  SERVICE_CHARGE_PERCENTAGE_DESCRIPTION,
} from "../dictionary/texts";
import { PostStatusBanner } from "./post-status-banner";
import { grey } from "@mui/material/colors";

export const ViewPropertyBody = ({
  postData,
  mediaIndexCount,
  setMediaIndexCount,
  setIsSliderOpen,
  setIsInstaReelOpen,
  isOwnerViewPost,
}) => {
  TimeAgo.addDefaultLocale(en);
  const timeAgo = new TimeAgo("en-US");
  const { postInfo, postUid } = postData || {};
  console.log("postInfo - mylogs", postInfo);
  const [serviceChargeInfoModal, setServiceChargeInfoModal] = useState({
    isServiceChargeInfoOpen: false,
  });
  const postTime = convertFirebaseTimestamp(postData?.postTime);
  const availableOn = convertFirebaseTimestamp(postInfo?.availableFrom);
  const isFreePost = postData?.postChargePercentage === "0";
  const isPostActive = postData?.postStatus === POST_STATUS.ACTIVE;

  return (
    <>
      <TextOkayModal
        value="isServiceChargeInfoOpen"
        state={serviceChargeInfoModal}
        setState={setServiceChargeInfoModal}
        text={
          isFreePost
            ? SERVICE_CHARGE_FREE_DESCRIPTION
            : SERVICE_CHARGE_PERCENTAGE_DESCRIPTION(
                postData?.postChargePercentage
              )
        }
      />
      <Spacer space={15} />
      <Box>
        <Box sx={{ m: "0px 20px" }}>
          <Carousel
            selectedItem={mediaIndexCount - 1}
            autoPlay={false}
            infiniteLoop={true}
            transitionTime={250}
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            swipeScrollTolerance={30}
            preventMovementUntilSwipeScrollTolerance={true}
            onChange={(index) => setMediaIndexCount(index + 1)}
          >
            {mediaSorted?.map((category, i) => {
              return postData?.media[category]?.map((property, i) => {
                return (
                  <Box
                    key={i}
                    onClick={() => {
                      setIsSliderOpen(true);
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        aspectRatio: "16/9",
                        objectFit: "fill",
                        borderRadius: "5px",
                        opacity: isPostActive ? "1" : "0.5",
                      }}
                      src={property}
                      alt=""
                    />
                    <Box
                      className="category"
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "12px",
                        backgroundColor: "black",
                        color: "white",
                        paddingX: "3px",
                        opacity: isPostActive ? "1" : "0.5",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </Typography>
                    </Box>
                  </Box>
                );
              });
            })}
          </Carousel>
        </Box>
        <Box sx={{ opacity: isPostActive ? "1" : "0.5" }}>
          {postData?.videosMedia?.instaUrl ? (
            <Stack direction="row" justifyContent="center">
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => setIsInstaReelOpen(true)}
              >
                Watch Video
              </Button>
            </Stack>
          ) : (
            <Spacer space={20} />
          )}
        </Box>
        <PostStatusBanner
          postStatus={postData?.postStatus}
          paymentStatus={postData?.paymentStatus}
          isOwnerViewPost={isOwnerViewPost}
          ownerActions={postData?.ownerActions}
        />
        <Box sx={{ opacity: isPostActive ? "1" : "0.5" }}>
          <Box sx={{ px: "20px" }}>
            <Stack spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack direction="row" alignItems="center" spacing={0.2}>
                  <CurrencyRupee
                    sx={{
                      fontSize: "19px",
                      fontWeight: "600",
                      fontFamily: "'Lumanosimo', serif",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "'Lumanosimo', serif",
                    }}
                  >
                    {Math.floor(postInfo?.montlyRent).toLocaleString("en-IN")} /
                    Month
                  </Typography>
                </Stack>
                <Typography sx={{ fontSize: "14px" }}>
                  {timeAgo.format(postTime)}
                </Typography>
              </Stack>
              <Stack spacing={0}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    color={primaryColor[900]}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Calistoga, serif",
                    }}
                  >
                    {postInfo?.address?.locality}
                  </Typography>
                  <Typography
                    sx={{
                      color: primaryColor[900],
                      fontWeight: "450",
                      fontSize: "15px",
                    }}
                  >
                    {postInfo?.bhk} Bhk
                  </Typography>
                </Stack>
                <Typography sx={addressTextStyle}>
                  <span sx={addressTextStyle}>
                    {postInfo?.fullName}
                    {": "}
                  </span>
                  {postInfo?.address?.floor && (
                    <span>
                      <span>
                        {parseInt(postInfo?.address?.floor, 10)}
                        <span
                          style={{ fontSize: "10px", verticalAlign: "super" }}
                        >
                          {addNumberSuffix(
                            parseInt(postInfo?.address?.floor, 10)
                          )}
                        </span>
                      </span>{" "}
                      floor,{" "}
                    </span>
                  )}
                  {getIntegratedFullAddress(postInfo?.address)}
                </Typography>
                <Typography sx={addressTextStyle}>
                  Near By : {postInfo?.address?.nearBy}
                </Typography>
                <Spacer space={10} />
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ fontSize: "15px", fontWeight: "450" }}>
                    Service charge - {postData?.postChargePercentage}%
                  </Typography>
                  <IconButton
                    onClick={() =>
                      setServiceChargeInfoModal({
                        isServiceChargeInfoOpen: true,
                      })
                    }
                  >
                    <Info
                      sx={{ fontSize: "16px", color: `${primaryColor[800]}` }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
            <Spacer space={10} />
            <Title title="Preferences For" />
            <Spacer space={8} />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {postInfo?.preferences?.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{
                    color: "white",
                    fontWeight: "550",
                    bgcolor: `${primaryColor[600]}`,
                  }}
                />
              ))}
            </Box>
            <Spacer space={20} />
            <Title title="Property Details" />
            <Spacer space={3} />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Post Id</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography> {trimString(postData?.postUid, 11)}</Typography>
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(postData?.postUid);
                    }}
                    sx={{ padding: "2px" }}
                  >
                    <ContentCopy
                      padding={0}
                      sx={{ fontSize: "16px", color: primaryColor[800] }}
                    />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Post Type</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  {getPostTypeValues(postData?.postType)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Available from</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {availableOn.getDate()}{" "}
                  {availableOn.toLocaleString("default", {
                    month: "short",
                  })}
                  , {availableOn.getFullYear()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Property Type</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{postInfo?.propertyType}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Area</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {postInfo?.area} {" sqft"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Security</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center">
                  <CurrencyRupee
                    sx={{
                      fontSize: "14px",
                      color: grey[900],
                    }}
                  />
                  <Typography>
                    {Math.floor(postInfo?.security).toLocaleString("en-IN")}
                    {" /-"}
                  </Typography>
                </Stack>
              </Grid>
              {postInfo?.maintenanceCharge && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={grid1Style}>Maintenance</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center">
                      <CurrencyRupee
                        sx={{
                          fontSize: "14px",
                          color: grey[900],
                        }}
                      />
                      <Typography>
                        {Math.floor(postInfo?.maintenanceCharge).toLocaleString(
                          "en-IN"
                        )}

                        <span style={{ fontSize: "14px" }}>{" / month"}</span>
                      </Typography>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Lift</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{postInfo?.lift ? "Available" : "No"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Bike Parking</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {postInfo?.parking?.bike ? "Available" : "No"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={grid1Style}>Car Parking</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {postInfo?.parking?.car ? "Available" : "No"}
                </Typography>
              </Grid>
            </Grid>
            <Spacer space={20} /> {/* TODO: shift this at the end */}
            {postInfo?.furnishment?.amenities && ( // TODO: check empty object
              <Box>
                <Title title="Amenities Details" />
                <Spacer space={8} />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {Object.entries(postInfo?.furnishment?.amenities || {})?.map(
                    ([key, amenity]) => (
                      <Chip
                        variant="outlined"
                        key={key}
                        label={`${amenity?.count} x ${amenity?.label}`}
                        sx={chip1Style}
                      />
                    )
                  )}
                </Box>
                <Spacer space={20} />
              </Box>
            )}
            {postInfo?.features?.propertyFeatures?.length && (
              <Box>
                <Title title="Property Features" />
                <Spacer space={8} />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {postInfo?.features?.propertyFeatures?.map((feature, i) => (
                    <Chip
                      icon={<Done sx={chip2IconStyle} />}
                      variant="outlined"
                      key={i}
                      label={feature}
                      sx={chip2Style}
                    />
                  ))}
                </Box>
                <Spacer space={20} />
              </Box>
            )}
            {postInfo?.features?.societyBuildingFeatures?.length && (
              <Box>
                <Title title="Society/Building Features" />
                <Spacer space={8} />
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {postInfo?.features?.societyBuildingFeatures?.map(
                    (feature, i) => (
                      <Chip
                        icon={<Done sx={chip2IconStyle} />}
                        variant="outlined"
                        key={i}
                        label={feature}
                        sx={chip2Style}
                      />
                    )
                  )}
                </Box>
                <Spacer space={20} />
              </Box>
            )}
            {postInfo?.propertyDescription && (
              <Box>
                <Title title="About Property" />
                <Typography>{postInfo?.propertyDescription}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Title = ({ title }) => {
  const titleArray = title?.split(" ");
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <Typography
        sx={{
          fontSize: "19px",
          fontWeight: "500",
          fontFamily: "Calistoga, serif",
        }}
      >
        {titleArray[0]}
      </Typography>
      <Typography
        color={primaryColor[900]}
        sx={{
          fontSize: "19px",
          fontWeight: "500",
          fontFamily: "Calistoga, serif",
        }}
      >
        {[...titleArray].slice(1).join(" ")}
      </Typography>
    </Stack>
  );
};

const addressTextStyle = {
  fontSize: "15px",
};

const grid1Style = {
  color: grey[800],
};

const chip1Style = {
  color: grey[800],
  bgcolor: "white",
  fontWeight: "600",
  height: "28px",
};

const chip2Style = {
  // color: "white",
  // bgcolor: `${primaryColor[600]}`,
  color: grey[900],
  bgcolor: "white",
  fontWeight: "550",
  borderRadius: "8px",
  height: "28px",
};

const chip2IconStyle = {
  color: `${primaryColor[800]} !important`,
};
