import { isDevMode } from "../utils/is-dev-mode";
import FLAGS from "../flags/flags";
import { DesktopViewNotAvailableBanner } from "./desktop-not-available-banner";
import { UseMobileDevice } from "../hooks/use-mobile-device";
import { UseTabletDevice } from "../hooks/use-tablet-device";
import { isIOS } from "react-device-detect";

export const MobileView = ({ children }) => {
  const isMobileDevice = UseMobileDevice();
  const isTabletDevice = UseTabletDevice();

  return isMobileDevice ||
    (isTabletDevice && isIOS) ||
    (isDevMode() && FLAGS.DESKTOP_VIEW) ? (
    <>{children}</>
  ) : (
    <>
      <DesktopViewNotAvailableBanner />
    </>
  );
};
